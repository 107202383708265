import { useCallback } from "react"
import { hotjar } from "react-hotjar"
import { initKlaviyo } from "src/utils/klaviyo"

const isDev = process.env.GATSBY_ACTIVE_ENV === "development" || process.env.NODE_ENV === "development"

export const useKlaviyo = () => {
  if (typeof window !== `undefined`) hotjar.initialize(isDev ? 2534531 : 2069322, 6)

  if (typeof window !== 'undefined') initKlaviyo()

  const cleanData = data =>
    JSON.parse(
      JSON.stringify(data, (k, v) =>
        ["id", "internal", "parent", "metadata"].includes(k) || k.startsWith("_")
          ? undefined
          : ["image", "thumbnail"].includes(k)
          ? { alt: v?.alt, url: v?.asset?.url }
          : ["slug", "handle"].includes(k)
          ? v?.current
          : ["date", "dateCreated", "dateUpdated", "publishedAt"].includes(k)
          ? `${new Date(v).toISOString()}`
          : ["content", "subcontent", "additionalContent", "biography", "description"].includes(k) && Array.isArray(v)
          ? v?.map(({ children }) => children?.[0]?.text)?.join("<br/>")
          : Array.isArray(v) && !v?.length
          ? undefined
          : v?.constructor === Object && Object.entries(v)?.length === 0
          ? undefined
          : typeof v === "boolean"
          ? v
          : v || undefined
      )
    )

  const trackCustomer = useCallback(async (customer, isHack) => {
    if (typeof window === "undefined") return
    if (isDev) `[KLAVIYO] trackCustomer fired!`
    window.klaviyo?.push([
      "identify",
      {
        email: `${customer?.email}`,
        first_name: `${customer?.firstName || ""}`,
        last_name: `${customer?.lastName || ""}`,
      },
    ])

    /**
     * TODO: This is a hack to make sure the correct customer data is tracked.
     * Need to find a better way to fix this.
     */
    if (isHack) return
    setTimeout(() => trackCustomer(customer, true), 2000)
  }, [])

  const trackMember = useCallback(member => {
    if (typeof window === "undefined") return
    if (isDev) console.log(`[KLAVIYO] trackMember fired!`)
    window.klaviyo?.push([
      "identify",
      {
        "Membership Active": member?.membership?.status,
        "Membership Plan": `${member?.membership?.type}`,
      },
    ])
    hotjar.identify(member?.contact?.email, {
      email: member?.contact?.email,
      membership_plan: `${member?.membership?.type}`,
      membership_active: member?.membership?.status || false,
    })
  }, [])

  const trackSearch = useCallback((type, term, results) => {
    if (typeof window === "undefined") return
    if (isDev) console.log(`[KLAVIYO] trackSearch(${type}) fired!`)
    if (term?.length) {
      window.klaviyo?.push([
        "track",
        `Searched ${type}`,
        {
          term: `${term}`,
          results: results,
        },
      ])
    }
  }, [])

  const trackEvent = useCallback((type, item) => {
    if (typeof window === "undefined") return
    if (isDev) console.log(`[KLAVIYO] trackEvent(${type}) fired!`)
    window.klaviyo?.push([
      "track",
      type,
      {
        ...cleanData(item),
      },
    ])
  }, [])

  return {
    trackCustomer,
    trackMember,
    trackSearch,
    trackEvent,
  }
}
