import React from "react"
import { Link } from "gatsby"

import { withWidget } from "./withWidget"
import { WidgetItems } from "./WidgetItems"
import { Icon } from "../../Icon/Icon"
import { DiscIcon, ProfileLink, ProfileMobile, ProfileH5, Bubble, Dropdown, Image } from "./Widget.styled"

export const Widget = withWidget(({ notification = 0, member, customer, ready, items }: Props): JSX.Element => {
  return (
    <>
      <ProfileLink
        as={Link}
        to={customer ? "/account/profile" : "/account/login"}
        title={member?.title ? member?.title : customer ? customer?.firstName : null}
        className={"group"}
      >
        {!customer && ready ? (
          <Icon name={"user"} size={"m"} colour={"tertiary"} disc />
        ) : member?.imageUrl && ready ? (
          <Image image={{ ...member?.image, layout: "fullWidth" }} alt={member?.title} />
        ) : ready ? (
          <DiscIcon name={"user"} size={"m"} colour={"tertiary"}>
            <ProfileH5>{member?.title ? member?.title?.slice(0, 2) : customer?.firstName?.slice(0, 2)}</ProfileH5>
          </DiscIcon>
        ) : null}
        {notification ? <Bubble>{notification}</Bubble> : null}
        {customer ? (
          <Dropdown>
            <WidgetItems links={items} />
          </Dropdown>
        ) : null}
      </ProfileLink>
      <ProfileMobile className={"group"}>
        {!customer && ready ? (
          <Icon name={"user"} size={"m"} colour={"tertiary"} disc />
        ) : member?.imageUrl && ready ? (
          <Image image={{ ...member?.image, layout: "fullWidth" }} alt={member?.title} />
        ) : ready ? (
          <DiscIcon name={"user"} size={"m"} colour={"tertiary"}>
            <ProfileH5>{member?.title ? member?.title?.slice(0, 2) : customer?.firstName?.slice(0, 2)}</ProfileH5>
          </DiscIcon>
        ) : null}

        {notification ? <Bubble>{notification}</Bubble> : null}

        {customer ? (
          <Dropdown>
            <WidgetItems links={items} />
          </Dropdown>
        ) : null}
      </ProfileMobile>
    </>
  )
})

export interface Props {
  notification?: number
  member?: any
  customer?: any
  items?: any
  active?: boolean
  ready?: boolean
  loading?: boolean
}
