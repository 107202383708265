import { useMemo, useState } from "react"
import sanityClient from "@sanity/client"
import blocksToHtml from "@sanity/block-content-to-html"

import { useApp } from "./useApp"
import { useFunctions } from "./useFunctions"
import { useImage } from "./useImage"
import { useData } from "./useData"
import { useKlaviyo } from "./useKlaviyo"
import { useSanityContext } from "../providers/sanity"

export const useSanityMentor = () => {
  const { setLoaded } = useApp()
  const {
    loadingRecommended,
    setLoadingRecommended,
    recommendedMentors,
    setRecommendedMentors,
    loadingSimilar,
    setLoadingSimilar,
    similarMentors,
    setSimilarMentors,
    loadingNewArrival,
    setLoadingNewArrival,
    newArrivalMentors,
    setNewArrivalMentors,
  } = useSanityContext()
  const {
    config: {
      services: { sanity },
    },
    member,
    setMember,
  } = useApp()
  const {
    data: { profilePlaceholder, profileDefault },
  } = useData()
  const { mentorCreate, requestCreate, requestUpdate, loading } = useFunctions()
  const { getGatsbyImageData } = useImage()
  const { trackMember } = useKlaviyo()
  const [loadingLocal, setLoading] = useState(false)
  const [selectedMember, setSelectedMember] = useState(false)

  const client = sanityClient({
    projectId: sanity?.projectId,
    dataset: sanity?.dataset,
    useCdn: true,
    // apiVersion: "2022-02-03"
  })

  const clientNoCDN = sanityClient({
    projectId: sanity?.projectId,
    dataset: sanity?.dataset,
    useCdn: false,
    // apiVersion: "2022-02-03"
  })

  const createMentor = async ({ customer, interaction, seeking, selected }) => {
    setLoading(true)
    const { status, body } = await mentorCreate({ customer, interaction, seeking, selected })
    if (status === "success") await getMentor({ email: customer?.email })
    setLoading(false)
  }

  const createMentorRequest = async ({ mentor, data }) => {
    setLoading(true)
    const { status, body } = await requestCreate({ mentor, member, data })
    setLoading(false)
    return status === "success"
  }

  const updateMentorRequest = async ({ id, data }) => {
    setLoading(true)
    const { status, body } = await requestUpdate({ id, data })
    setLoading(false)
    return status === "success"
  }

  const mentorProjection = `{"plan": membership.plan->, "memberStartDate": coalesce(membership._startDate->, ""), "imageUrl": coalesce(image.asset->url, ""), "imageAsset": coalesce(image.asset->, {}), "industries": coalesce(preferences.industries[].industry->, {}), "industryPrimary": coalesce(preferences.industryPrimary.industry->, {}), "industrySecondary": coalesce(preferences.industrySecondary.industry->, {}), "seeking": coalesce(preferences.seeking[]->, []), "skills": coalesce(preferences.skills[]->, []), "careerImageAssets": coalesce(content.careers[].image.asset->, {}), ...}`
  const mentorCompletedFilter = `[title != "" && role != "" && location != "" && defined(image)]`
  const mentorMembershipFilter = `[memberStartDate != ""]`

  const fieldsToCompletion = [
    { key: "title", title: "title" },
    { key: "role", title: "role" },
    { key: "location", title: "location" },
    { key: "imageUrl", title: "profile pic" },
  ]
  const isProfileCompleted = mentor => fieldsToCompletion?.filter(field => !mentor?.[field.key]?.length)?.length === 0
  const incompleteDetails = mentor => fieldsToCompletion?.filter(field => !mentor?.[field.key]?.length)?.map(x => x.title)

  const fetchMentorData = async ({ id, ids }: { id?: string; ids?: string[] }) => {
    const filters = ['[_type == "mentor"]', ids ? `[_id in [${ids.map(id => `"${id}"`)}]]` : `[_id == "${id}"]`]
    const sorts = ["order(title)"]
    const projection = mentorProjection
    const query = ["*"].concat(filters).concat(sorts).concat([projection]).join("|")

    try {
      const items = await clientNoCDN.fetch(query)

      const members = items.map(item => {
        const mentor = normalizeMentor(item)
        return {
          ...mentor,
          completed: isProfileCompleted(item),
          incompleteDetails: incompleteDetails(item),
          eligible: item?.membership?.status && item?.membership?.booking > 0,
        }
      })

      return ids ? members : members[0]
    } catch (err) {
      console.error(err?.message)
    }

    return null
  }

  const getMentor = async ({ email }) => {
    const filters = ['[_type == "mentor"]', "[contact.email == $email]"]
    const sorts = ["order(title)"]
    const projection = mentorProjection
    const query = ["*"].concat(filters).concat(sorts).concat([projection]).join("|")
    const params = { email: email?.toLowerCase() }
    return clientNoCDN
      .fetch(query, params)
      .then(items => {
        const item = normalizeMentor(items[0])
        const member = {
          ...item,
          completed: isProfileCompleted(item),
          incompleteDetails: incompleteDetails(item),
          eligible: item?.membership?.status && item?.membership?.booking > 0,
        }
        if (items?.length) {
          console.log("ext", { member })
          setMember(member)
          trackMember(member)
          if (recommendedMentors?.length) setRecommendedMentors([])
        }
        setLoaded(["member"])
        return member
      })
      .catch(err => {
        console.error(err?.message)
        setLoaded(["member"])
      })
  }
  const getNewArrivalMentors = (email, limit = 8, newest = true, cache = []) => {
    if (!newArrivalMentors?.length && !loadingNewArrival && email) {
      setLoadingNewArrival(true)

      const filters = [
        '[_type == "mentor"]',
        "[contact.email != $email]",
        "[membership.remaining > 0]",
        "[membership.status == true]",
        mentorCompletedFilter,
      ]
      if (newest) filters.push(mentorMembershipFilter)
      const sorts = ["order(_updatedAt desc)"]

      if (newest) sorts.unshift("order(startDate desc)")

      const projection = mentorProjection
      const pagination = `[0...${limit}]`
      const query = ["*"].concat(filters).concat([pagination]).concat(sorts).concat([projection]).join("|")
      const params = { email: email || "" }

      client
        .fetch(query, params)
        .then(items => {
          if (items?.length > 3) {
            setNewArrivalMentors([...cache, ...items.map(normalizeMentor)])
            setLoadingNewArrival(false)
          } else {
            getNewArrivalMentors(email, limit, false, items?.length ? items.map(normalizeMentor) : [])
          }
        })
        .catch(err => {
          console.error(err?.message)
          setNewArrivalMentors([])
          setLoadingNewArrival(false)
        })
    }
  }
  const getRecommendedMentors = ({ preferences, contact, email }, limit = 8, newest = false, cache = []) => {
    if (preferences && (contact || email)) {
      if (!recommendedMentors?.length && !loadingRecommended) {
        setLoadingRecommended(true)
        const industries = preferences?.seeking?.map(item => item?._id).filter(Boolean) || []

        const filters = [
          '[_type == "mentor"]',
          "[contact.email != $email]",
          "[membership.remaining > 0]",
          "[membership.status == true]",
          mentorCompletedFilter,
        ]
        if (!newest && industries?.length) {
          filters.push(`[preferences.skills[]._ref in $industries]`)
        }
        const sorts = ["order(title)"]
        if (newest) sorts.unshift("order(_createdAt)")
        const projection = mentorProjection
        const pagination = `[0...${limit}]`
        const query = ["*"].concat(filters).concat([pagination]).concat(sorts).concat([projection]).join("|")
        const params = { email: contact?.email || email || "", industries }
        client
          .fetch(query, params)
          .then(items => {
            if (items?.length > 3) {
              setRecommendedMentors([...cache, ...items.map(normalizeMentor)])
              setLoadingRecommended(false)
            } else {
              getRecommendedMentors(member, 8, true, items?.length ? items.map(normalizeMentor) : [])
            }
          })
          .catch(err => {
            console.error(err?.message)
            setRecommendedMentors([])
            setLoadingRecommended(false)
          })
      }
    }
  }

  const getSimilarMentors = ({ preferences, contact }, limit = 8) => {
    if (!similarMentors?.length && !loadingSimilar && preferences?.seeking?.length > 0) {
      setLoadingSimilar(true)
      const industries = preferences?.seeking?.map(item => item?._id) || []
      const filters = ['[_type == "mentor"]', "[contact.email != $email]", "[membership.status == true]", mentorCompletedFilter]
      if (industries?.length) filters.push("[preferences.seeking[]._ref in $industries]")
      const sorts = ["order(title)"]
      const projection = mentorProjection
      const pagination = `[0...${limit}]`
      const query = ["*"].concat(filters).concat([pagination]).concat(sorts).concat([projection]).join("|")
      const params = { email: contact?.email || "", industries }
      client.fetch(query, params).then(items => {
        if (items?.length) {
          setSimilarMentors(items.map(normalizeMentor))
          setLoadingSimilar(false)
        }
      })
    }
  }

  const normalizeMentor = item => ({
    ...item,
    membership: {
      ...item?.membership,
      vip: item?.plan?.slug?.current?.includes("vip"),
      free: !item?.plan || item?.plan?.slug?.current === "free-membership",
      type: item?.plan?.slug?.current || "expired",
      plan: item?.plan,
    },
    link: `/mentors/${item?.slug?.current}`,
    completed: isProfileCompleted(item),
    incompleteDetails: incompleteDetails(item),
    image: item?.imageUrl
      ? getGatsbyImageData({ ...item?.image }, { width: 800, aspectRatio: 1 })
      : profilePlaceholder.childImageSharp.gatsbyImageData,
    imageDefault: item?.imageUrl
      ? getGatsbyImageData({ ...item?.image }, { width: 800, aspectRatio: 1 })
      : profileDefault.childImageSharp.gatsbyImageData,
    thumbnail: item?.imageUrl
      ? getGatsbyImageData({ ...item?.image }, { width: 600, aspectRatio: 1 })
      : profilePlaceholder.childImageSharp.gatsbyImageData,
    links: transformLinks(item?.contact),
    badges: transformBadges(item),
    content: {
      ...item?.content,
      careers: transformCareers(item),
    },
    preferences: {
      ...item?.preferences,
      industryPrimary: {
        ...item?.preferences?.industryPrimary,
        industry: item?.industryPrimary || item?.preferences?.industryPrimary?.industry,
      },
      industrySecondary: {
        ...item?.preferences?.industrySecondary,
        industry: item?.industrySecondary || item?.preferences?.industrySecondary?.industry,
      },
      seeking: item?.seeking || item?.preferences?.seeking,
      skills: item?.skills || item?.preferences?.skills,
    },
  })

  const transformCareers = mentor =>
    mentor?.content?.careers?.map(({ startDate, endDate, image, ...item }, index) => {
      const start = startDate && Date.parse(startDate) && new Date(startDate)
      const end = endDate && Date.parse(endDate) && new Date(endDate)
      const diff = start && !isNaN(start) ? (end && !isNaN(end) ? end.getTime() : new Date().getTime()) - start.getTime() : 0
      const time = parseFloat(diff / 1000).toFixed(3),
        years = Math.floor(time / 60 / 60 / 24 / 30 / 12),
        months = Math.floor(time / 60 / 60 / 24 / 30) - years * 12

      const duration = years > 0 && months > 0 ? ` (${years > 0 ? `${years} yrs` : ``}${months > 0 ? ` ${months} mos` : ``})` : ""
      const timeRange = startDate && `${startDate} – ${endDate || `Present`}${duration}`

      return {
        ...item,
        startDate,
        endDate,
        image: getGatsbyImageData(image && mentor?.careerImageAssets?.length ? { ...image, asset: mentor?.careerImageAssets[index] } : image, {
          maxWidth: 200,
        }),
        originalImage: image,
        timeRange,
      }
    })

  const transformLinks = links => [
    { title: `Instagram`, url: links?.instagram && `https://www.instagram.com/${links?.instagram}`, icon: `instagram` },
    { title: `Email`, url: links?.email && `mailto:${links?.email}`, icon: `email` },
    { title: `LinkedIn`, url: links?.linkedin && `https://www.linkedin.com/in/${links?.linkedin}`, icon: `linkedin` },
    { title: `Facebook`, url: links?.facebook && `https://www.facebook.com/${links?.facebook}`, icon: `facebook` },
    { title: `Share`, url: links?.website, icon: `share` },
  ]

  const transformBadges = mentor => [
    ...(mentor?.nearestLocation || mentor?.location ? [{ text: mentor?.nearestLocation || mentor?.location, icon: "pin" }] : []),
    { text: mentor?.company, icon: "user" },
    { text: mentor?.preferences?.industryPrimary?.level, icon: "award" },
  ]

  let subscription = null
  const getSelectedMember = async ({ email }) => {
    const filters = ['[_type == "mentor"]', "[contact.email == $email]"]
    const sorts = ["order(title)"]
    const projection = mentorProjection
    const query = ["*"].concat(filters).concat(sorts).concat([projection]).join("|")
    const params = { email: email?.toLowerCase() }

    clientNoCDN
      .fetch(query, params)
      .then(items => {
        const item = normalizeMentor(items[0])
        const member = {
          ...item,
          completed: isProfileCompleted(item),
          incompleteDetails: incompleteDetails(item),
          eligible: item?.membership?.status && item?.membership?.booking > 0,
        }
        if (items?.length) {
          setSelectedMember({ ...item, type: "mentor" })
        }
      })
      .catch(err => {
        console.error(err?.message)
      })
    if (!subscription) {
      subscription = client.listen(query, params).subscribe(({ result }) => {
        getSelectedMember(result?.contact)
      })
    }
  }

  return {
    client,
    loading,
    createMentor,
    createMentorRequest,
    updateMentorRequest,
    fetchMentorData,
    getMentor,
    recommendedMentors,
    getRecommendedMentors,
    setRecommendedMentors,
    similarMentors,
    getSimilarMentors,
    setSimilarMentors,
    normalizeMentor,
    mentorProjection,
    selectedMember,
    getSelectedMember,
    newArrivalMentors,
    getNewArrivalMentors,
  }
}

export const useSanityEvent = () => {
  const {
    member,
    config: {
      services: { sanity },
    },
  } = useApp()
  const {
    loadingLiveEvents,
    setLoadingLiveEvents,
    liveEvents,
    setLiveEvents,
    loadingSimilarEvents,
    setLoadingSimilarEvents,
    similarEvents,
    setSimilarEvents,
  } = useSanityContext()
  const { getGatsbyImageData } = useImage()
  const [event, setEvent] = useState(false)
  const [loading, setLoading] = useState(false)

  const client = sanityClient({
    projectId: sanity?.projectId,
    dataset: sanity?.dataset,
    useCdn: true,
    // apiVersion: "2022-02-03"
  })

  const clientNoCDN = sanityClient({
    projectId: sanity?.projectId,
    dataset: sanity?.dataset,
    useCdn: false,
    // apiVersion: "2022-02-03"
  })

  const projection = `{"imageUrl": image.asset->url, "imageAsset": image.asset->, "categories": categories[]->, "attendees": coalesce(attendees[]->, {}), "attendeeImages": coalesce(attendees[]->image.asset->, {}), ...}`

  const getEvent = async ({ id }) => {
    setLoading(true)
    const filters = ['[_type == "event"]', "[_id == $id]"]
    const sorts = ["order(title)"]
    const query = ["*"].concat(filters).concat(sorts).concat([projection]).join("|")
    const params = { id }
    clientNoCDN
      .fetch(query, params)
      .then(items => {
        const event = normalizeEvent(items[0])
        if (items?.length) {
          setEvent(event)
          setLoading(false)
        }
      })
      .catch(err => {
        console.error(err?.message)
      })
  }

  const getEvents = async ({ ids }) => {
    if (!loadingLiveEvents) {
      setLoadingLiveEvents(true)
      const filters = ['[_type == "event"]', "[_id in $ids]"]
      const sorts = ["order(title)"]
      const query = ["*"].concat(filters).concat(sorts).concat([projection]).join("|")
      const params = { ids }
      clientNoCDN
        .fetch(query, params)
        .then(items => {
          const events = items?.map(normalizeEvent)
          if (items?.length) {
            setLiveEvents(events)
            setLoadingLiveEvents(false)
          }
        })
        .catch(err => {
          console.error(err?.message)
        })
    }
  }

  const getRelatedEvents = ({ id, categories }, limit = 8) => {
    if (!loadingSimilarEvents && !similarEvents?.length && categories?.length > 0) {
      setLoadingSimilarEvents(true)
      const relationship = categories?.map(item => item?._id) || []
      const filters = ['[_type == "event"]', "[_id != $id]", `[categories[]._ref in $relationship]`, `[0...${limit}]`]
      const sorts = ["order(title)"]
      const query = ["*"].concat(filters).concat(sorts).concat([projection]).join("|")
      const params = { id, relationship }
      client.fetch(query, params).then(items => {
        if (items?.length) {
          setSimilarEvents(items.map(normalizeEvent))
          setLoadingSimilarEvents(false)
        }
      })
    }
  }

  const normalizeEvent = item => {
    const node = item?.node || item
    const spacesThreshold = 10
    const spaces = node?.registrationPlaces - (node?.attendees?.length || 0)
    return {
      ...node,
      link: `/events/${node?.slug?.current}`,
      image: node?.image ? getGatsbyImageData({ ...node?.image, asset: node?.imageAsset || node?.image?.asset }, { maxWidth: 800 }) : null,
      attending: member && checkAttending(node),
      attendees: node?.attendees?.length
        ? node?.attendees?.map((attendee, index) => ({
            ...attendee,
            image: getGatsbyImageData({ ...attendee?.image, asset: node?.attendeeImages?.[index] || attendee?.image?.asset }, { maxWidth: 250 }),
          }))
        : [],
      registration: {
        enabled: node?.registration,
        spaces: node?.registrationPlaces && spaces <= spacesThreshold && spaces,
      },
    }
  }

  const checkAttending = item =>
    item?.attendees?.length && item?.attendees.filter(item => item?.id == member?._id || item?._id == member?._id)?.length > 0

  return {
    client,
    event,
    events: liveEvents,
    related: similarEvents,
    loadingLiveEvents,
    liveEvents,
    setLiveEvents,
    similarEvents,
    setSimilarEvents,
    getEvent,
    getEvents,
    getRelatedEvents,
    normalizeEvent,
    loading,
  }
}

export const useSanityPodcast = () => {
  const {
    config: {
      services: { sanity },
    },
  } = useApp()
  const { getGatsbyImageData } = useImage()
  const [related, setRelated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lastPath, setLastPath] = useState("")

  const client = sanityClient({
    projectId: sanity?.projectId,
    dataset: sanity?.dataset,
    useCdn: true,
    // apiVersion: "2022-02-03"
  })

  const projection = `{"imageUrl": image.asset->url, "imageAsset": image.asset->, ...}`

  const getRelatedPodcast = ({ id }, limit = 8, path) => {
    if (!loading) {
      if (path !== lastPath) {
        setLastPath(path)
        setLoading(true)
        const filters = ['[_type == "podcast"]', "[_id != $id]", `[0...${limit}]`] //`[categories[]._ref in $relationship]`,
        const sorts = ["order(title)"]
        const query = ["*"].concat(filters).concat(sorts).concat([projection]).join("|")
        const params = { id }
        client.fetch(query, params).then(items => {
          if (items?.length) setRelated(items.map(normalizePodcast))
          setLoading(false)
        })
      }
    }
  }

  const normalizePodcast = item => ({
    ...item,
    link: `/podcasts/${item?.slug?.current}`,
    image: item?.image ? getGatsbyImageData({ ...item?.image, asset: item?.imageAsset || item?.image?.asset }, { maxWidth: 800 }) : null,
    thumbnail: item?.image
      ? getGatsbyImageData({ ...item?.image, asset: item?.imageAsset || item?.image?.asset }, { maxWidth: 600, maxHeight: 400 })
      : null,
  })

  return { client, getRelatedPodcast, normalizePodcast, related, loading }
}

export const useSanityArticle = () => {
  const {
    config: {
      services: { sanity },
    },
  } = useApp()
  const { getGatsbyImageData } = useImage()
  const [related, setRelated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lastPath, setLastPath] = useState("")

  const client = sanityClient({
    projectId: sanity?.projectId,
    dataset: sanity?.dataset,
    useCdn: true,
    // apiVersion: "2022-02-03"
  })

  const projection = `{"imageUrl": image.asset->url, "imageAsset": image.asset->, "categories": categories[]->,...}`

  const getRelatedArticles = ({ id, categories }, limit = 8, path) => {
    if (!loading && categories?.length > 0) {
      if (categories?.length < 1 || path !== lastPath) {
        setLastPath(path)
        setLoading(true)
        const relationship = categories?.map(item => item?._id) || []
        const filters = ['[_type == "article"]', `[categories[]._ref in $relationship]`, `[0...${limit}]`]
        const sorts = ["order(title)"]
        const query = ["*"].concat(filters).concat(sorts).concat([projection]).join("|")
        const params = { id, relationship }
        client.fetch(query, params).then(items => {
          if (items?.length) setRelated(items.map(normalizeArticle))
          setLoading(false)
        })
      }
    }
  }

  const normalizeArticle = item => ({
    ...item,
    link: `/articles/${item?.slug?.current}`,
    image: item?.image ? getGatsbyImageData({ ...item?.image, asset: item?.imageAsset || item?.image?.asset }, { maxWidth: 800 }) : null,
    thumbnail: item?.image
      ? getGatsbyImageData({ ...item?.image, asset: item?.imageAsset || item?.image?.asset }, { maxWidth: 600, maxHeight: 400 })
      : null,
  })

  return { client, getRelatedArticles, normalizeArticle, related, loading }
}

export const useSanityRequest = () => {
  const {
    config: {
      services: { sanity },
    },
    member,
    requests,
    setRequests,
    unReviewsMentors,
    setUnReviewedMentors,
  } = useApp()
  const {
    data: { profilePlaceholder, profileDefault },
  } = useData()
  const { getGatsbyImageData } = useImage()
  const { normalizeMentor, mentorProjection } = useSanityMentor()
  const [loading, setLoading] = useState(false)
  const [ready, setReady] = useState(false)

  const client = sanityClient({
    projectId: sanity?.projectId,
    dataset: sanity?.dataset,
    useCdn: false,
    // apiVersion: "2022-02-03"
  })

  const clientWithCdn = sanityClient({
    projectId: sanity?.projectId,
    dataset: sanity?.dataset,
    useCdn: true,
    // apiVersion: "2022-02-03"
  })

  let subscription = null
  const projection = `{"mentee": mentee->, "menteeImageAsset": mentee->image.asset->, "mentor": mentor->, "mentorImageAsset": mentor->image.asset->, "feedback": feedback->, ...}`

  const getRequests = (limit = 8) => {
    if (!loading) {
      if (subscription !== null) subscription()
      setLoading(true)
      const filters = ['[_type == "request"]', "[mentor._ref == $id || mentee._ref == $id]"]
      const sorts = ["order(_updatedAt desc, _createdAt desc)", `[0...${limit}]`]
      const query = ["*"].concat(filters).concat(sorts).concat([projection]).join("|")
      const params = { id: member?._id }
      client.fetch(query, params).then(items => {
        if (items?.length) setRequests(items?.map(normalizeRequest))
        // setLoading(false)
      })

      subscription = client.listen(query, params).subscribe(({ result }) => getResultMentor(result))
    }
  }

  const getRequest = async id => {
    const filters = ['[_type == "request"]', "[request._id == $id]"]
    const query = ["*"].concat(filters).concat([projection]).join("|")
    const params = { id }
    return (await client.fetch(query, params))[0] || null
  }

  const getMentorRequests = async (id: string, limit = 8) => {
    const filters = ['[_type == "request"]', "[mentor._ref == $id || mentee._ref == $id]"]
    const sorts = ["order(_updatedAt desc, _createdAt desc)", `[0...${limit}]`]
    const query = ["*"].concat(filters).concat(sorts).concat([projection]).join("|")
    const params = { id }
    const items = await clientWithCdn.fetch(query, params)

    if (items?.length) {
      return items?.map(normalizeRequest)
    }

    return null
  }

  const getResultMentor = result => {
    const filters = ['[_type == "mentor"]', "[_id in $ids]"]
    const sorts = ["order(title)"]
    const projection = mentorProjection
    const query = ["*"].concat(filters).concat(sorts).concat([projection]).join("|")
    const params = { ids: [result?.mentee?._ref, result?.mentor?._ref] }
    client.fetch(query, params).then(items => {
      if (items?.length) {
        const members = items?.map(normalizeMentor)
        setRequests(prevResult => [
          normalizeRequest({
            ...result,
            mentor: members?.filter(item => item?._id === result?.mentor?._ref)?.[0],
            mentee: members?.filter(item => item?._id === result?.mentee?._ref)?.[0],
            read: false,
            new: true,
          }),
          ...prevResult?.filter(item => item?._id !== result?._id),
        ])
      }
    })
  }

  const normalizeRequest = item => ({
    ...item,
    link: `/account/requests/${item?._id}`,
    mentor: {
      ...item?.mentor,
      image: item?.mentor?.image?.asset
        ? getGatsbyImageData({ ...item?.mentor?.image, asset: item?.mentorImageAsset || item?.mentor?.image?.asset }, { maxWidth: 200 })
        : profilePlaceholder?.childImageSharp?.gatsbyImageData,
    },
    mentee: {
      ...item?.mentee,
      image: item?.mentee?.image?.asset
        ? getGatsbyImageData({ ...item?.mentee?.image, asset: item?.menteeImageAsset || item?.mentee?.image?.asset }, { maxWidth: 200 })
        : profilePlaceholder?.childImageSharp?.gatsbyImageData,
    },
  })

  const getUnreviewedMentors = () => {
    if (!loading) {
      setLoading(true)
      const filters = ['[_type == "request"]', "[mentee._ref == $id]"]
      const sorts = ["order(_updatedAt desc, _createdAt desc)"]
      const query = ["*"].concat(filters).concat(sorts).concat([projection]).join("|")
      const params = { id: member?._id }
      client.fetch(query, params).then(items => {
        if (items?.length) {
          const result = items.filter(item => {
            if ((item.feedback && item.feedback.length > 0) || !item.mentor.title) {
              return false
            }
            return true
          })
          setUnReviewedMentors(result?.map(normalizeRequest) || [])
        }
        // setLoading(false)
      })
    }
  }

  return {
    client,
    getRequests,
    getRequest,
    getMentorRequests,
    normalizeRequest,
    requests,
    loading,
    getUnreviewedMentors,
    unReviewsMentors,
    ready,
    setReady,
  }
}

export const useSanityBlocks = () => {
  const h = blocksToHtml.h

  const serializers = {
    types: {
      code: (props: any) => h("pre", { className: props.node.language }, h("code", props.node.code)),
    },
  }

  const getHTML = blocks =>
    blocksToHtml({
      blocks: blocks,
      serializers,
    })

  return {
    getHTML,
  }
}
