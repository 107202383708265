import { useState, useCallback } from "react"

import { useCore } from "./useCore"
import { useLocation } from "./useLocation"
import { useApp } from "./useApp"

export const useFunctions = () => {
  const {
    helpers: { decodeBase64 },
  } = useCore()
  const { shopify } = useLocation()
  const {
    member,
    customer,
    setCustomer,
    config: {
      services: { functions },
    },
  } = useApp()
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [adding, setAdding] = useState(false)
  const [errors, setErrors] = useState([])

  /**
   * Uses firebase functions
   */
  const request = useCallback(
    async (endpoint, data, upload = false) => {
      setErrors([])

      const local = "development" === process.env.NODE_ENV && process.env.GATSBY_CLOUD_PREVIEW !== "1"

      const formData = new FormData()
      if (upload) formData.append("file", data?.file)

      return fetch(
        `${local ? `${functions?.endpoint}/she-mentors/australia-southeast1` : functions?.endpoint}${endpoint}?shop=${shopify?.shopName}${
          data?.id ? `&id=${data.id}` : ""
        }${data?.key ? `&key=${data.key}` : ""}`,

        {
          method: "post",
          ...(!upload && {
            headers: {
              "Content-Type": "application/json",
            },
          }),
          //@ts-ignore
          body: !upload ? JSON.stringify(data) : formData,
        }
      )
        .then(res => res.json())
        .then(result => {
          setLoading(false)
          setAdding(false)
          setDeleting(false)
          setErrors(result?.status === "error" ? [result?.body] : result?.userErrors)
          return result
        })
        .catch(error => {
          console.error(error)
          setLoading(false)
          setAdding(false)
          setDeleting(false)
          //@ts-ignore
          setErrors([error])
          return error
        })
    },
    [setLoading, setErrors, fetch, shopify]
  )

  /**
   * Uses gatsby functions
   */
  const requestV2 = useCallback(
    async (endpoint, data, method = "post", upload = false) => {
      setErrors([])

      const formData = new FormData()
      if (upload) formData.append("file", data?.file)

      let body: any = undefined
      if (upload) {
        body = formData
      } else if (data) {
        body = JSON.stringify(data)
      }

      return fetch(`/api${endpoint}?shop=${shopify?.shopName}${data?.id ? `&id=${data.id}` : ""}${data?.key ? `&key=${data.key}` : ""}`, {
        method,
        ...(!upload && {
          headers: {
            "Content-Type": "application/json",
          },
        }),
        //@ts-ignore
        body,
      })
        .then(res => res.json())
        .then(result => {
          setLoading(false)
          setAdding(false)
          setDeleting(false)
          setErrors(result?.status === "error" ? [result?.body] : result?.userErrors)
          return result
        })
        .catch(error => {
          console.error(error)
          setLoading(false)
          setAdding(false)
          setDeleting(false)
          //@ts-ignore
          setErrors([error])
          return error
        })
    },
    [setLoading, setErrors, fetch, shopify]
  )

  const customerSubscribe = useCallback(
    async email => {
      setLoading(true)
      const { customer, userErrors } = await request("/customerSubscribe", { email })
      return { customer, userErrors }
    },
    [request]
  )

  const customerTags = useCallback(
    async tags => {
      setLoading(true)
      const id = decodeBase64(customer?.id)
      const { userErrors } = await request("/customerTags", { id, tags })
      if (!userErrors?.length) setCustomer(prevState => ({ ...prevState, tags }))
    },
    [customer, decodeBase64, request, setCustomer]
  )

  const formSubmission = useCallback(
    async (form, data, ipData, userAgent) => {
      setLoading(true)
      const id: string = form?.id
      const type: string = "default" //form?.types[data?.type?.toLowerCase()]?.title?.toLowerCase()
      const subject: string = form?.subject || `Form submission from ${form?.title}`
      const { status, body } = await request("/formSubmission", { id, type, subject, data, ipData, userAgent })
      return { status, body }
    },
    [request]
  )

  const mentorCreate = useCallback(
    async data => {
      setLoading(true)

      const { status, body } = await request("/mentorCreate", { ...data })
      return { status, body }
    },
    [request]
  )

  const mentorImageUpload = useCallback(
    async file => {
      setAdding(true)

      const { status, body } = await request("/mentorMediaUpload", { id: member?._id, file }, true)
      return { status, body }
    },
    [request, member]
  )
  const mentorMediaCareerUpload = useCallback(
    async (file, key, member) => {
      setAdding(true)

      const { status, body } = await request("/mentorMediaCareerUpload", { id: member?._id, file, key, member }, true)
      return { status, body }
    },
    [request, member]
  )

  const mentorUpdate = useCallback(
    async data => {
      data.type === "content" ? setAdding(true) : setLoading(true)
      console.log("🚀 ~ file: useFunctions.ts:135 ~ useFunctions ~ data:", data)

      const { status, body } = await request("/mentorUpdate", { ...data })
      return { status, body }
    },
    [request]
  )
  const mentorCareerUpdate = useCallback(
    async data => {
      data.type === "content" ? setAdding(true) : setLoading(true)

      const { status, body } = await request("/mentorUpdate", { ...data })
      return { status, body }
    },
    [request]
  )

  const eventRegister = useCallback(
    async data => {
      setLoading(true)

      const { status, body } = await request("/eventRegister", { ...data })
      return { status, body }
    },
    [request]
  )

  const eventCancel = useCallback(
    async data => {
      setLoading(true)

      const { status, body } = await request("/eventCancel", { ...data })
      return { status, body }
    },
    [request]
  )

  const requestCreate = useCallback(
    async data => {
      setLoading(true)

      const { status, body } = await requestV2("/requestCreate", { ...data })
      return { status, body }
    },
    [request]
  )

  const requestUpdate = useCallback(
    async data => {
      setLoading(true)

      const { status, body } = await requestV2("/requestUpdate", { ...data })
      return { status, body }
    },
    [requestV2]
  )

  const streamTokenGenerate = useCallback(
    async (data: { [key: string]: any }) => {
      setLoading(true)

      const { status, body } = await requestV2("/streamTokenGenerate", { ...data })
      return { status, body }
    },
    [requestV2]
  )

  const messagesOnNewUnread = useCallback(
    async (data: { [key: string]: any }) => {
      setLoading(true)
      const { status, body } = await requestV2("/messagesOnNewUnread", { ...data })
      return { status, body }
    },
    [requestV2]
  )

  const messagesOnChatEnable = useCallback(
    async (data: { [key: string]: any }) => {
      setLoading(true)
      const { status, body } = await requestV2("/messagesOnChatEnable", { ...data })
      return { status, body }
    },
    [requestV2]
  )

  const getUnreadMessagesCount = useCallback(
    async (data: { [key: string]: any }) => {
      setLoading(true)
      const { status, body } = await requestV2("/unreadMessagesCount", { ...data })
      return { status, body }
    },
    [requestV2]
  )

  const messageSend = useCallback(
    async data => {
      setLoading(true)

      const { status, body } = await request("/messageSend", { ...data })
      return { status, body }
    },
    [request]
  )

  const messageRead = useCallback(
    async data => {
      setLoading(true)
      const { status, body } = await request("/messageRead", { ...data })
      return { status, body }
    },
    [request]
  )

  const membershipCheckout = useCallback(
    async data => {
      setLoading(true)
      const { status, body } = await request("/membershipCheckoutCreate", { ...data })
      return { status, body }
    },
    [request]
  )

  const membershipCheckoutSession = useCallback(
    async data => {
      setLoading(true)
      const { status, body } = await request("/membershipCheckoutSession", { data })
      return status === "success" ? body : false
    },
    [request]
  )

  const membershipPortal = useCallback(
    async data => {
      setLoading(true)
      const { status, body } = await request("/membershipPortalCreate", { ...data })
      return status === "success" ? body : false
    },
    [request]
  )

  const membershipProducts = useCallback(async () => {
    setLoading(true)
    const { status, body } = await request("/membershipProducts", {})
    return status === "success" ? body : false
  }, [request])

  const membershipGetSubscription = useCallback(
    async data => {
      setLoading(true)
      const { status, body } = await request("/membershipGetSubscription", { ...data })
      return { status, body }
    },
    [request]
  )

  const trackEvent = useCallback(async () => {
    setLoading(true)
    const { status, body } = await request("/trackEvent", {})
    return status === "success" ? body : false
  }, [request])

  const membershipChange = useCallback(
    async data => {
      setLoading(true)
      const { status, body } = await request("/membershipChange", { ...data })
      return { status, body }
    },
    [request]
  )

  return {
    customerSubscribe,
    customerTags,
    formSubmission,
    mentorCreate,
    mentorImageUpload,
    mentorCareerUpdate,
    mentorMediaCareerUpload,
    mentorUpdate,
    requestCreate,
    requestUpdate,
    streamTokenGenerate,
    messagesOnNewUnread,
    messagesOnChatEnable,
    getUnreadMessagesCount,
    messageSend,
    messageRead,
    eventRegister,
    eventCancel,
    membershipCheckout,
    membershipCheckoutSession,
    membershipPortal,
    membershipProducts,
    trackEvent,
    loading,
    deleting,
    adding,
    errors,
    membershipGetSubscription,
    membershipChange,
  }
}
