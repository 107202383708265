import React, { useMemo } from "react"
import { navigate } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useCustomerLogout } from "../../../hooks/useCustomer"
import { useStripe } from "../../../hooks/useStripe"
import { useCore } from "../../../hooks/useCore"
import { useSavedItemsCount } from "../../../hooks/useSavedItems"
import { useSanityRequest } from "../../../hooks/useSanity"

export const withWidget = Component =>
  React.memo(({ name = "Widget", notification, member, customer, ...props }) => {
    const { logoutCustomer } = useCustomerLogout()
    const { openPortal, loading } = useStripe()
    const { ready, unReviewMentors } = useApp()
    const {
      helpers: { storage },
    } = useCore()
    const {
      config: {
        settings: { keys },
      },
    } = useApp()
    const { getUnreviewedMentors } = useSanityRequest()

    const handleLogout = () => {
      logoutCustomer()
    }

    const navigateMembership = () => {
      const customerTokens = storage.get(keys?.customer)
      const isAuth = customerTokens?.accessToken.length > 0 && new Date(customerTokens?.expiresAt) >= new Date()
      if (isAuth && member != null) {
        navigate("/account/plans")
      }
    }

    const { count: savedItemsCount } = useSavedItemsCount()
    if (member && !unReviewMentors?.length) {
      getUnreviewedMentors()
    }

    let items = [
      { icon: "home", title: "Dashboard", link: "/account/dashboard" },
      { icon: "user", title: "Profile", link: "/account/profile" },
      { icon: "heartFill", title: `${savedItemsCount > 0 ? "Saved Items (" + savedItemsCount + ")" : "Saved Items"}`, link: "/account/saved" },
    ]
    if (member?.membership?.status) {
      items.push(
        { icon: "email", title: "Messages", link: "/messages" },
        { icon: "calendar", title: "Bookings", link: "/account/requests" },
        { icon: "star", title: `${unReviewMentors?.length > 0 ? "Reviews (" + unReviewMentors?.length + ")" : "Reviews"}`, link: "/account/reviews" },
        { icon: "settings", title: loading ? "Loading..." : "Membership", onClick: () => openPortal(), link: "" }
      )
    } else {
      items.push({ icon: "settings", title: loading ? "Loading..." : "Membership", onClick: () => navigate("/memberships"), link: "" })
    }

    items.push({ icon: "eye", title: "Logout", onClick: () => handleLogout(), link: "" })

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          {...props}
          items={items}
          member={member}
          customer={customer}
          notification={notification}
          ready={ready}
          active={member?.membership?.status}
          newMember={true}
          loading={loading}
        />
      ),
      [member, customer, notification, ready, loading, items, unReviewMentors]
    )
  })
