import React from "react"
import { Link } from "gatsby"

import { withPopupUpgrade } from "./withPopupUpgrade"
import { Popup } from "./Popup"
import { Close } from "./Popup.styled"
import { SignIn, SignInLink } from "../Acccount/Register/RegisterForm.styles"
import { MentorCardContent } from "../Mentor/MentorCardContent"
import { StyledPopupTitle, StyledPopupContent, StyledPopupP, StyledPopupButton, StyledEmoji } from "../../styled/Popup"
import { Icon } from "../Icon/Icon"

export const PopupUpgrade = withPopupUpgrade(
  ({ customer, level, plans, handleCheckout, activePopup, handlePopup, loading }: Props): JSX.Element => (
    <Popup active={activePopup} setActive={handlePopup} full>
      <Close type={`button`} onClick={() => handlePopup(false)}>
        <Icon name={"cross"} size={"s"} colour={"tertiary"} />
      </Close>
      <StyledPopupTitle>{level === "expired" ? "Membership required" : "Ready to upgrade?"}</StyledPopupTitle>
      <StyledPopupP>Please upgrade to access this feature.</StyledPopupP>

      <StyledPopupContent>
        <br />
        <MentorCardContent theme={`white`}>
          <StyledEmoji>😊</StyledEmoji>
        </MentorCardContent>
      </StyledPopupContent>

      <br />
      {!level?.includes("vip") && level !== "standard" && (
        <StyledPopupButton colour={`dark`} size={`large`} theme={`primary`} as={Link} to={"/memberships"}>{`View Memberships`}</StyledPopupButton>
      )}
      {level === "standard" && (
        <StyledPopupButton colour={`dark`} size={`large`} theme={`primary`} onClick={() => handleCheckout(plans?.vip?.product?.plan?.id, "VIP")}>
          {loading ? "Loading..." : `Upgrade to VIP`}
        </StyledPopupButton>
      )}
      {!customer && (
        <SignIn>
          {`Have an account?`} <SignInLink theme={`primary`} to={`/account/login`}>{`Log In`}</SignInLink>
        </SignIn>
      )}
    </Popup>
  )
)

export interface Props {
  title?: String
  content?: any
  level?: string
  customer?: any
  plans?: any
  redirectUrl?: string
  activePopup?: boolean
  loading?: boolean
  handlePopup?: (show: boolean) => void
  handleCheckout?: (price: string, type: string, redirectUrl?: string) => void
}
