import { useCallback, useContext } from "react"

import { TrackingContext } from "../providers/tracking"

import { useSettings } from "./useSettings"

const isDev = process.env.GATSBY_ACTIVE_ENV === "development" || process.env.NODE_ENV === "development"

export const useAnalytics = () => {
  const { tracked, setTracked, gtm, VisibilitySensor } = useContext(TrackingContext)
  const { tracking } = useSettings()

  const trackPageView = useCallback((memberId: string) => {
    setTimeout(() => {
      if (isDev) console.log(`[TRACKING] pageView (${document.title}) fired!`)
      gtm.initialize({
        gtmId: tracking?.googleTagManagerId,
        dataLayer: {
          event: "page_view",
          page_title: document.title,
          page_path: `${document.location.pathname}${document?.location?.search || ""}`,
          path_location: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
          member_id: memberId,
        },
      })
    }, 200)
  }, [gtm, tracking])

  const trackCustomEvent = useCallback(
    async (event, data) => {
      setTimeout(() => {
        if (isDev) console.log(`[TRACKING] customEvent (${event}) fired!`)
        gtm.initialize({
          gtmId: tracking?.googleTagManagerId,
          dataLayer: {
            event,
            ...data,
          },
        })
      }, 200)
    },
    [gtm, tracking]
  )

  const trackEvent = useCallback(async (memberId: string) => {
    await setTracked(true)
    await trackPageView(memberId)
  }, [tracked, setTracked, trackPageView])

  return {
    trackEvent,
    trackPageView,
    trackCustomEvent,
    VisibilitySensor,
  }
}
