import { useContext, useCallback } from "react"

import { useApp } from "./useApp"
import { LocationContext } from "../providers/location"

export const useLocation = () => {
  const location = useContext(LocationContext)
  const { config } = useApp()

  const userData = useCallback(async () => {
    try {
      const response = await fetch(config?.services?.location?.serviceUrl)
      const data = await response.json()

      return {
        ipData: data,
        userAgent: navigator.userAgent || "",
      }
    } catch (e) {
      /**
       * Dev notes: Historically, there's issues fetching this service on iOS devices
       * due to some CORS issue. On desktop, this is not an issue.
       */
      return null
    }
  }, [fetch, config])

  return { ...location, userData }
}
